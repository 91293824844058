










import { Component, Vue } from 'vue-property-decorator'

@Component
export default class FloatingButton extends Vue {
  get fontIcon() {
    if (this.$vuetify.breakpoint.md) {
      return 30
    }
    if (this.$vuetify.breakpoint.lg) {
      return 35
    }
    if (this.$vuetify.breakpoint.xlOnly) {
      return 40
    }
    return 20
  }

  scrollOnTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }
}
